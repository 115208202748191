@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@layer base {
  * {
    @apply text-white font-inter;
  }
  span {
    @apply text-inherit;
  }
  html {
    @apply scroll-smooth;
    @apply scrollbar-thin scrollbar-thumb-[#C1C1C1]/60 scrollbar-track-[#1C1D20];
    @apply bg-dark_blue;
  }
  h1 {
    @apply text-[1.75rem] md:text-[2.375rem] xl:text-[3rem] font-bold leading-tight;
  }

  h2 {
    @apply text-[1.25rem] md:text-[1.625rem] xl:text-[2rem] font-semibold;
  }

  p {
    @apply font-normal text-white;
  }

  a {
    @apply font-normal text-white;
  }

  img {
    @apply pointer-events-none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .t_section {
    @apply text-[1.75rem] md:text-[2.75rem] xl:text-[3.75rem] font-bold leading-tight text-yellow;
  }
  .t_xs {
    @apply text-xs font-normal;
  }
  .t_sm {
    @apply text-xs font-normal md:text-sm;
  }
  .t_base {
    @apply text-xs font-normal md:text-sm xl:text-base;
  }
  .t_lg {
    @apply text-sm font-normal md:text-base xl:text-lg;
  }
  .t_xl {
    @apply text-base font-normal md:text-lg xl:text-xl;
  }
  .t_2xl {
    @apply text-lg font-normal md:text-xl xl:text-2xl;
  }

  .t_36px {
    @apply text-[1.25rem] md:text-[1.5rem] xl:text-[2rem] font-normal;
  }
  .t_40px {
    @apply text-[1.25rem] md:text-[1.875rem] xl:text-[2.5rem] font-normal;
  }

  .t_48px {
    @apply text-[1.25rem] md:text-[2.5rem] xl:text-[3rem] font-normal;
  }

  .b {
    @apply font-semibold uppercase duration-200 rounded-lg bg-yellow hover:bg-dark_yellow text-dark_blue;
    @apply shadow-2xl shadow-yellow/30;
  }
  .b_xs {
    @apply px-8 py-2 b t_xs;
  }
  .b_sm {
    @apply px-8 py-2 b t_sm;
  }
  .b_base {
    @apply px-8 py-2 b t_base;
  }
  .b_lg {
    @apply px-8 py-2 b t_lg xl:px-12 md:px-8;
  }
  .b_xl {
    @apply px-8 py-2 b t_xl xl:py-4 xl:px-12 md:py-3 md:px-8;
  }

  .b_xs_outline {
    @apply shadow-none b_xs;
    @apply duration-200 bg-transparent border hover:bg-transparent border-yellow hover:border-dark_yellow text-yellow;
  }

  .mask_edges {
    @apply -mx-20 md:-mx-40 xl:-mx-80;
  }

  .bg_primary_blur {
    @apply border border-solid backdrop-blur-md bg-dark_blue/80 border-yellow;
  }

  .bg_header {
    @apply bg-[#1C1D20]/80 backdrop-blur-md;
  }

  .limited-content {
    @apply relative flex flex-col mx-auto max-w-[120rem];
  }
  .card-rounded {
    @apply rounded-2xl lg:rounded-[2rem];
  }
  .mask-image {
    @apply z-0 object-cover w-full h-auto max-h-full pointer-events-none;
  }
  .card-border {
    @apply border-[3px] border-yellow card-rounded shadow-2xl;
  }
  .h-line-wrapper {
    @apply w-full px-4 mx-auto max-w-figma md:px-6 xl:px-12;
  }
  .h-line {
    @apply w-full h-[1px] bg-[#7F7F7F];
  }
  .v-line {
    @apply w-[1px] h-auto bg-[#7F7F7F];
  }

  .scalable {
    filter: blur(0px) !important;
    -webkit-filter: blur(0px) !important;
    -moz-filter: blur(0px) !important;
    -ms-filter: blur(0px) !important;
  }

  .timer {
    @apply bg-clip-text bg-gradient-to-b from-[#f6d878]/100 to-white !text-transparent;
  }
}

.progress-block__strip {
  position: relative;
  height: 1.625rem;
  background-color: #2d2d2d;
}
.progress-block__strip-inner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  height: 100%;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  background-color: #fff62280;
  width: 0;
  transition: width 0.5s;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .progress-block__strip-title {
    font-size: 1rem;
  }
}
.progress-block__strip-sub-title {
  font-size: 0.833333em;
}
.progress-block__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.625rem;
  font-weight: 600;
  color: #ffffff;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .progress-block__percent {
    font-size: 0.875rem;
  }
}

.timeline {
  color: #ffffff;
  cursor: pointer;
}
.timeline__inner-wrap {
  position: relative;
}
.timeline__list {
  display: flex;
}
.timeline-scrollbar {
  width: 100%;
  height: 4px;
  background-color: rgba(14, 73, 57, 0.3);
}
.timeline-scrollbar__handle {
  height: 100%;
  background-color: #fff622;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background-color: #fff622;
}
